<template>
  <div class="coupon-box">
    <van-sticky>
      <div class="imitate-search-box">
        <div class="imitate-input" @click="handleSearch">
          <van-icon
            :name="require('@/assets/img/common/search.svg')"
            color="#999"
            size="16"
          />
          <span class="imitate-placehodor">搜索</span>
        </div>
      </div>
    </van-sticky>
    <van-list
      v-model:loading="form.loading"
      v-model:error="form.error"
      :finished="form.finished"
      :immediate-check="false"
      error-text="请求失败，点击重新加载"
      :finished-text="couponData.length == 0 ? '暂无数据' : '没有更多了'"
      @load="getCouponData"
    >
      <div
        class="p15"
        v-for="item in couponData"
        :key="item.coupon_id"
        @click="openInstuctions(item)"
      >
        <div class="coupon-class">
          <div class="circular"></div>
          <div class="flex flex-align-center">
            <div
              class="coupon-price flex flex-direction-column flex-justify-center"
            >
              <div class="tc">
                <span class="fs12 clff fw400">￥</span>
                <span class="fs36 clff fw700">
                  {{ Number(item.sub_money) }}
                </span>
              </div>
              <div class="fs12 tc clff fw400" v-if="item.use_type === 0">
                无门槛
              </div>
              <div class="fs12 tc clff fw400" v-if="item.use_type === 1">
                满 {{ Number(item.full_money) }} 元可用
              </div>
            </div>
            <div class="coupon-message p12">
              <div class="fs14 fw400 cl00 txt-line-1">
                {{ item.coupon_name }}
              </div>
              <div class="mt6 cl99 fs12 cl99">
                可用时间：{{ item.receive_time }}
              </div>
              <div class="flex flex-align-center flex-justify-between mt5">
                <van-button
                  round
                  type="primary"
                  size="mini"
                  :disabled="item.sub_total == 0"
                  @click.stop="handleShare(item, index)"
                >
                  发送
                </van-button>
                <van-icon name="arrow-down" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="item.showMore"
          class="coupon-instructions-box p12 cl00 fw400 fs12"
        >
          <div>
            <div>使用说明：</div>
            <div class="mt12">
              {{ item.coupon_desc }}
            </div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script setup>
import { Toast } from 'vant'
import { ref, onMounted, reactive, defineProps } from 'vue'
import moment from 'moment'
import { getCouponList, couponShareImg } from '../../../service/checkQr.service'
import { useRouter } from 'vue-router'
import { sendChatMessageAllType } from '@/utils/nwechat.js'
import { formatParamsDistribution } from '@/utils/index.js'
import { sendMonitor } from '@/service/quickSend.service.js'
const router = useRouter()
const instructions = ref(false)
const couponId = ref(null)
const couponData = ref([])
const memberId = ref(null)
const couponParams = reactive({
  page: 1,
  page_size: 10,
  coupon_name: ''
})
const form = ref({
  loading: false,
  error: false,
  finished: false,
  count: 0
})
const share_data_id = ref('')

const props = defineProps({
  externalUserId: {
    type: String,
    default: () => ''
  },
  chatId: {
    type: String,
    default: () => ''
  }
})

const openInstuctions = (obj) => {
  if (obj.showMore) {
    obj.showMore = !obj.showMore
  } else {
    obj.showMore = true
  }
}

// 发送前的埋点回调
const beforeSend = async (coupon_id) => {
  let { data, code } = await sendMonitor({
    payable_id: coupon_id,
    type: 'coupon',
    user_id: props.externalUserId
  })
  if (code == 200) {
    share_data_id.value = data.share_data_id
  }
  return Promise.resolve(true)
}

const handleShare = async (item) => {
  Toast.loading({
    message: '加载中',
    forbidClick: true,
    duration: 0
  })
  if (props.externalUserId) {
    await beforeSend(item.coupon_id)
  }
  try {
    let params = {}
    params.coupon_id = item.coupon_id
    const { data } = await couponShareImg(params)
    let title = data.title
    let imgUrl = data.imgUrl
    console.log('id是否获取成功', props.externalUserId, props.chatId)
    let page = `pages/coupon/receiveCoupon/index.html?coupon_id=${item.coupon_id}&member_id=${memberId.value}&is_share=1&externalUserId=${props.externalUserId}&chatId=${props.chatId}`
    page = await formatParamsDistribution(
      page,
      props.externalUserId,
      props.chatId,
      'b2c_coupon'
    )

    // 如果存在埋点id加入埋点id
    if (share_data_id.value)
      page = `${page}&share_data_id=${share_data_id.value}`

    let shareObj = {
      appid: data.appid, //小程序的appid
      title,
      imgUrl, //小程序消息的封面图。必须带http或者https协议头，否则报错 $apiName$:fail invalid imgUrl
      page //小程序消息打开后的路径，注意要以.html作为后缀，否则在微信端打开会提示找不到页面
    }
    // console.log('发送前参数', shareObj)
    await sendChatMessageAllType('miniprogram', {
      miniprogram: { ...shareObj }
    })
    Toast.clear()
  } catch (e) {
    console.log('报错', e)
    Toast.clear()
  }
}

const getCouponData = async () => {
  console.log('couponDatalength', couponData.value.length)
  if (couponData.value.length > 0) {
    couponParams.page += 1
  }
  try {
    form.value.loading = true
    const { data, code } = await getCouponList(couponParams)
    memberId.value = data.member_id
    if (code !== 200) {
      form.value.error = true
      form.value.count = 0
      throw code
    }
    form.value.count = data.total_num || 0
    couponData.value = couponData.value.concat(data.list)
    form.value.loading = false

    // 翻到最后一页
    if (
      couponData.value.length < couponParams.page_size ||
      form.value.count === couponData.value.length
    ) {
      form.value.finished = true
    }
  } catch (error) {
    form.value.error = true
    form.value.finished = true
    console.log(error)
  } finally {
    form.value.loading = false
    return Promise.resolve(true)
    // return Promise.resolve(true)
  }
}

// 下拉刷新
// const onRefresh = async () => {
//   console.log('11111')
//   couponData.value = []
//   form.value.refreshing = true
//   couponParams.page = 1
//   await getCouponData()
//   form.value.refreshing = false
// }

// 刷新条时间
const nowDate = () => {
  return moment(new Date()).format('HH:mm:ss')
}

// 跳转搜索页
const handleSearch = () => {
  let userId = memberId.value
  router.push({
    path: '/b2cCoupon-search',
    query: {
      userId,
      externalUserId: props.externalUserId,
      chatId: props.chatId
    }
  })
}

// getCouponData()

onMounted(() => {
  getCouponData()
})
</script>

<style lang="less" scoped>
.coupon-box {
  height: calc(100vh - 68px);
  overflow-y: auto;
}
// 模拟搜索框
.imitate-search-box {
  padding: 12px 16px;
  padding-top: 4px;
  background-color: #fff;
  display: flex;
  align-items: center;

  .imitate-input {
    line-height: 32px;
    background-color: #f5f5f5;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #999999;
    border-radius: 5px;
    cursor: pointer;

    flex-grow: 1;
    .imitate-placehodor {
      margin-left: 8px;
    }
  }
}
.coupon-class {
  position: relative;
  width: 100%;
  //   height: 100px;
  .coupon-price {
    width: 40%;
    // height: 100px;
    align-self: normal;
    background: #1773fa;
  }
  .coupon-message {
    width: 60%;
    // height: 100px;
    background: #ffffff;
  }
  .circular {
    position: absolute;
    left: -8px;
    top: 46%;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
  }
}

.coupon-instructions-box {
  width: 100%;
  background: #ffffff;
}
:deep(.van-button--mini) {
  width: 56px !important;
  font-size: 14px !important;
}
</style>
