<template>
  <div>
    <goods
      v-if="status === 'goods'"
      :externalUserId="externalUserId"
      :chatId="chatId"
    />
    <coupon
      v-if="status === 'coupon'"
      :externalUserId="externalUserId"
      :chatId="chatId"
    />
    <footer class="page-footer bgf flex flex-align-center flex-justify-center">
      <div @click="handlStatus('goods')" class="tc">
        <div v-if="status === 'goods'">
          <img src="@/assets/img/b2cMall/goods_check.svg" alt="" />
          <div class="fs12 fw400 txt-blue">商品</div>
        </div>
        <div v-else>
          <img src="@/assets/img/b2cMall/goods.svg" alt="" />
          <div class="fs12 fw400 cl99">商品</div>
        </div>
      </div>
      <div @click="handlStatus('coupon')" class="ml48 tc">
        <div v-if="status === 'coupon'">
          <img src="@/assets/img/b2cMall/coupon_check.svg" alt="" />
          <div class="fs12 fw400 txt-blue">优惠券</div>
        </div>
        <div v-else>
          <img src="@/assets/img/b2cMall/coupon.svg" alt="" />
          <div class="fs12 fw400 cl99">优惠券</div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import coupon from './coupon/index.vue'
import goods from './goods/index.vue'
import { getCurExternalContact, getCurExternalChat } from '@/utils/nwechat.js'

const status = ref('goods')
const route = useRoute()
// 外部联系人userID
const externalUserId = ref('')
// 群ID
const chatId = ref('')

const handlStatus = (e) => {
  status.value = e
}
// 初始化数据
const initData = () => {
  getCurExternalContact()
    .then((res) => {
      externalUserId.value = res
      console.log('获取个人ID', externalUserId.value)
    })
    .catch((err) => {
      console.log('获取个人ID报错', err)
    })
  getCurExternalChat()
    .then((res) => {
      chatId.value = res
      console.log('获取的群ID', chatId.value)
    })
    .catch((err) => {
      console.log('获取的群ID报错', err)
    })
}

onMounted(() => {
  initData()
  // externalUserId.value = 'wm1DICCQAAf_T-OHtTnzp0r-OdEq4b6g'
})
</script>

<style lang="less" scoped>
.page-footer {
  position: absolute;
  z-index: 2;
  bottom: 0px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: calc(12px + env(safe-area-inset-bottom) / 2);
}
</style>
